import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Wrapper = styled.footer`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};
  background-color: #fff;
  color: ${props => props.theme.colors.base};
`

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  padding: 2rem 0;
  margin: 0 1.5em;
  text-align: center;

  @media screen and (min-width: ${props => props.theme.responsive.small}) { 
    text-align: left;
  }
`

const ListItem = styled.li`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const Item = styled.li`
  display: inline-block;
  width: 100%;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
  a {
    font-weight: 600;
    padding: .25rem;
    display: inline-block;
    text-decoration: none;
    transition: all 0.2s;
    color: ${props => props.theme.colors.base};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
    &:visited {
      color: ${props => props.theme.colors.base};
    }
  }
`

const activeLinkStyle = {
  textDecoration: 'underline'
}

const Footer = () => (
  <Wrapper>
    <List>
      <Item>
        <Link to="/imprint/" activeStyle={activeLinkStyle}>
          Imprint
        </Link>{' '}{' '}
        <Link to="/privacy-policy/" activeStyle={activeLinkStyle}>
          Privacy
        </Link>{' '}{' '}
        <Link to="/terms-of-use/" activeStyle={activeLinkStyle}>
          Terms of use
        </Link>
      </Item>
    </List>
  </Wrapper>
)

export default Footer
