import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Logo from '../../static/logo_light.png'

import { Instagram } from 'react-feather';
import { Facebook } from 'react-feather';

const Header = styled.header`
  display: none;
  background: ${props => props.theme.colors.baseDark};
  border-bottom: 1px solid ${props => props.theme.colors.baseDark};
  width: 100%;
  padding: 1em 0;
`
const Nav = styled.nav`
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 0 1.5em;

  ul {
    display: flex;
    justify-content: space-between;
  }

  li {
    display: inline-flex;
    align-items: center;
    &:first-child {
      position: relative;
      margin: 0;
      flex-basis: 100%;
    }

    & + li {
      a {
        margin-left: 1em;
      }
    }

    a {
      text-decoration: none;
      color: DarkGray;
      font-weight: 500;
      transition: all 0.2s;
      &:hover {
        color: white;
      }
    }
  }

  .logoContainer {
    display: flex;
    align-items: center;
    font-size: 22px;
      color: #fff !important;
    img {
      margin-right: .5rem;
    }
  }

  .logo {
    width:auto;
    height:32px;
  }
`

const activeLinkStyle = {
  color: 'white',
}

const Menu = () => {
  return (
    <Header>
      <Nav>
        <ul>
          <li>
            <Link to="/" activeStyle={activeLinkStyle} className="logoContainer">
              <img className="logo" src={Logo} />
              snoopo
            </Link>
          </li>
          <li>
              <a
              href="https://www.instagram.com/snoopos/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram size={24} />
            </a>
            <a
              href="https://www.facebook.com/snoopos/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Facebook size={24} />
            </a>
            <Link to="/faq/" activeStyle={activeLinkStyle}>
              FAQ
            </Link>
          </li>
        </ul>
      </Nav>
    </Header>
  )
}

export default Menu
