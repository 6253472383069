const theme = {
  colors: {
    base: '#2B2F31', 
    baseDark: '#232627', 
    secondary: '#e9e9e9', 
    tertiary: '#f3f3f3', 
    highlight: '#489EFA', 
    white: '#fff', 
  },
  sizes: {
    maxWidth: '1050px',
    maxWidthCentered: '650px',
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em',
  },
}

export default theme
