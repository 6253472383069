import React from 'react'
import Helmet from 'react-helmet'
import config from '../../utils/siteConfig'
import Layout from '../../components/Layout'
import Container from '../../components/Container'
import PageTitle from '../../components/PageTitle'
import SEO from '../../components/SEO'

const PrivacyPolicy = ({ data }) => {
  const postNode = {
    title: `Privacy Policy - ${config.siteTitle}`,
  }

  return (
    <Layout>
      <Helmet>
        <title>{`Privacy Policy - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="Privacy Policy" customTitle />

        <Container>
        <PageTitle>Privacy Policy</PageTitle>
        <h1>Privacy Policy (Effective: August 2019)</h1>
    <p>We understand that the protection of your privacy is important to you and we take the protection of your personal data very seriously. It therefore goes without saying that we comply with the statutory data protection regulations. In addition, we consider it important that you, as our customer, always know when and how we collect and store your data, and how we use them. In this Privacy Policy, we explain how we collect and otherwise process (e.g. store, access, change and transfer) personal data when you use our mobile app. Personal data are all data that make you identifiable, e.g. name, address, e-mail addresses and user behavior. We would like to point out that data transmission via the Internet can result in security gaps. It is not technically feasible to ensure that the data transmitted are protected against access by third parties from end to end.</p>

    <h2>I. Name and Address of Controller</h2>
    <p>The controller within the meaning of the General Data Protection Regulation (GDPR), other national data protection legislation of the Member States and other data protection provisions is:</p>

    <p>
    Snoopo GmbH <br />
    Berliner Allee 34-36 <br />
    D-40212 Düsseldorf <br />
    Tel.: +49-173-3622450 <br />
    Email: <a href="mailto:info@snoopo.com">info@snoopo.com</a> <br />
    Webseite: <a href="https://www.snoopo.com">www.snoopo.com</a>
    </p>

    <h2>II. Data Protection Officer’s Contact Details</h2>
    <p>You can contact the controller’s data protection officer at the following address: Snoopo GmbH, Data Protection Officer, Berliner Allee 34-36, 40212 Düsseldorf E-mail: <a href="mailto:datenschutz@snoopo.com">datenschutz@snoopo.com</a></p>

    <h2>III. General Length of Storage of Personal Data</h2>
    <p>Unless there is an indication to the contrary or we specifically provide otherwise in this Privacy Policy, we will retain the personal data stored by our app until you request us to erase them or withdraw your consent to their storage or the reason for the storage has ceased to exist. Insofar as we are under a statutory duty to retain your data or there is otherwise a legally recognized reason to store the data (e.g. legitimate interest), we will not erase the data before the relevant reason for retaining data has ceased to exist.</p>

    <h2>IV. Legal Bases for Storing Personal Data</h2>
    <p>The processing of personal data is only permitted where a valid legal basis for the processing of such data exists. Insofar as we process your data, we regularly do this on the basis of your consent pursuant to Art. 6(1)(a) of the GDPR (e.g. if you voluntarily enter your data in the login screen), for the purpose of performing a contract pursuant to Art. 6(1)(b) of the GDPR (e.g. for making in-app purchases or using other app features that are subject to fees) or on the basis of our legitimate interests pursuant to Art. 6 (1)(f) of the GDPR, which must always be weighed against your interests (e.g. in connection with advertising activities). Where appropriate, we elaborate on the relevant legal bases for processing separately in this Privacy Policy.</p>

    <h2>V. Encryption</h2>
    <p>For security reasons and to protect the transfer of content of a confidential nature such as enquiries that you send to us as the app operator or communication between app users, such data are encrypted. This encryption prevents unauthorized third parties from being able to read data that you send to us.</p>

    <h2>VI. App Permissions</h2>
    <p>In order to be able to provide our services via the app, we need the following access permissions which allow us to access certain functions on your device:</p>

    <ul>
      <li>Location data</li>
      <li>Your smart phone’s device number</li>
      <li>Camera</li>
      <li>Photos / videos</li>
    </ul>

    <p>Access to these of your device’s functions is necessary in order to ensure that you can use the app’s features. The legal basis for this data processing is our legitimate interest within the meaning of Art. 6(1)(f) of the GDPR, your consent within the meaning of Art. 6(1)(a) of the GDPR or – where we have entered into a contract with you – the performance of our contractual obligations (Art. 6(1)(b) of the GDPR).</p>

    <h2>VII. Recording of Personal Data When You Use the App</h2>
    <p>We record the following personal data about you when you use our app:</p>

    <ul>
      <li>First and last name</li>
      <li>E-mail-address</li>
      <li>Usage data</li>
      <li>IP address</li>
      <li>Device ID</li>
      <li>Metadata</li>
    </ul>

    <p>The processing of such personal data is necessary to ensure that you can use the app’s features. The legal basis for this data processing is our legitimate interest within the meaning of Art. 6(1)(f) of the GDPR, your consent within the meaning of Art. 6(1)(a) of the GDPR or – where we have entered into a contract with you – the performance of our contractual obligations (Art. 6(1)(b) of the GDPR).</p>

    <p>We will retain the personal data recorded until you request us to erase them or withdraw your consent to their storage or the reason for the storage has ceased to exist.</p>

    <p>In addition, other personal data may be collected and processed in connection with other services and features offered by the app.</p>

    <h3>(1) Online-Shop</h3>
    <p>The data collected during the conclusion of a contract with us will be used to process and fulfill your order. The information which is mandatory so that we can carry out the contract is designated as such; where you supply other information, you do so on a voluntary basis. We will only share this information with third parties if doing so is lawful and necessary pursuant to Art. 6(1)(b) of the GDPR for carrying out our contract with you (cf. also section V. of this Privacy Policy). The legal basis of data processing is Art. 6(1)(b) of the GDPR because processing of the data is necessary for the conclusion or performance of a contract. We are required by commercial and tax law provisions to store your address, payment and order details for a period of 10 years.</p>

    <p>If you use PayPal to pay for your order, we will pass on your payment information for these purposes to PayPal (Europe) S.à r.l. et Cie, S.C.A., 22-24 Boulevard Royal, 2449 Luxembourg (hereinafter referred to as "PayPal"). Please refer to PayPal’s Privacy Statement for further information on data privacy, including information regarding the credit agencies used: <a href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full?locale.x=en_DE#3">https://www.paypal.com/de/webapps/mpp/ua/privacy-full?locale.x=en_DE#3</a>.</p>

    <h3>(2) Contacting Us</h3>
    <p>If you contact us (using the contact form or via e-mail, phone or fax), the data that you voluntarily provide will be stored and processed by us so that we can deal with your enquiry. The legal bases for processing are Art. 6(1)(a) and (b) of the GDPR, which permit data to be processed where consent has been voluntarily granted or to respond to an enquiry. We will retain the personal data recorded until you request us to erase them or withdraw your consent to their storage or the reason for the storage has ceased to exist (e.g. after we have finished processing your matter). The aforegoing is without prejudice to any mandatory provisions, in particular to those regarding retention periods. We will not pass on your data without your consent.</p>

    <h3>(3) Disbursement of Money Found</h3>
    <p>Where PayPal is used for the disbursement of any money found, we will pass on your payment information for these purposes to PayPal (Europe) S.à r.l. et Cie, S.C.A., 22-24 Boulevard Royal, 2449 Luxembourg (hereinafter referred to as “PayPal”). Please refer to PayPal’s Privacy Statement for further information on data privacy, including information regarding the credit agencies used: <a href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full?locale.x=en_DE#3">https://www.paypal.com/de/webapps/mpp/ua/privacy-full?locale.x=en_DE#3</a>.</p>

    <h2>VIII. Use of Online Marketplaces for E-Commerce</h2>
    <p>We offer our services on online platforms operated by other providers. In such cases, the privacy policies of the respective other platforms apply in addition to our own Privacy Policy. This applies in particular to the methods used by such platforms to count the number of persons reached and for customized marketing.</p>

    <p>Kinds of personal data processed: Master data (e.g. names and addresses), payment information (e.g. bank details, invoices and payment history), contact details (e.g. e-mail and phone numbers), contract information (e.g. subject-matter of contract, duration and customer category), usage data (e.g. websites visited, interest in their content and access times), metadata and communications data (e.g. device information and IP addresses).</p>

    <p>Purposes of processing: Contractual obligations and service</p>

    <p>Legal bases: Performance of a contract or to take steps at the request of the data subject prior to entering into a contract (Art. 6(1)(b) of the GDPR), legitimate interests (Art. 6(1)(f) of the GDPR).</p>

    <h3>Services and Service Providers Used:</h3>

    <ul>
      <li>Apple App Store: App and software sales platform; service provider: Apple Inc., Infinite Loop, Cupertino, CA 95014, USA; website: https://www.apple.com/ios/app-store/; Privacy Policy: <a href="https://www.apple.com/legal/privacy/en-ww/">https://www.apple.com/legal/privacy/en-ww/</a>.</li>
      <li>Google Play: App and software sales platform; service provider: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland, parent company: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; website: <a href="https://play.google.com/store/apps?hl=en">https://play.google.com/store/apps?hl=en</a>; Privacy Policy: <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.</li>
    </ul>

    <h2>IX. Plugins and Embedded Functions and Content</h2>

    <p>We embed, in our online offerings, function and content elements obtained from the servers of the respective providers (hereinafter referred to as “Third-Party Providers”). These can be, for example, graphics, videos or social media buttons and contributions (hereinafter referred to collectively as “Content”).</p>

    <p>Where Content is embedded, the Third-Party Provider of the Content needs to process the user’s IP address since, without it, the Content cannot be sent to the user’s browser. Thus your device’s IP address is needed to display such Content and to use such features. We strive to only use Content whose provider limits its use of IP addresses to the delivery of such Content. Furthermore, Third-Party Providers may use pixel tags (these are invisible graphics that are also referred to as web beacons) for statistical or marketing purposes. Pixel tags can collect information for analyzing the number of visitors to the pages of this website. The pseudonymized information may be stored in cookies on the user’s device and include, among other things, technical information regarding the user’s browser and operating system, links to other websites, the date and time of visits to our website, and other information regarding the use of our online offering. Furthermore, the pseudonymized information may be combined with information from other sources.
    </p>

    <p>Information about Legal Bases: Where we ask users for their consent to the deployment of Third-Party Providers, their consent is the legal basis for the processing of their personal data. Otherwise, our processing of a user’s data is based on our legitimate interests (i.e. our interest in providing efficient, commercially viable and user-friendly services).</p>

    <p>Purposes of processing: To provide our online offering and enhance its user-friendliness, contractual obligations and service, information requests and communication, direct marketing (e.g. per e-mail or by post), tracking (e.g. interest-based and behavioral profiling, and use of cookies), interest-based and behaviorally targeted marketing, profiling (creation of user profiles).</p>

    <p>Legal bases: Legitimate interests (Art. 6(1)(f) of the GDPR) and consent (Art. 6(1)(a) of the GDPR).</p>

    <h3>Services and Service Providers Used:</h3>

    <p>
      <strong>Facebook Social Plugins:</strong> Facebook Social Plugins - These may include, for example, content such as photos, videos or copy, and buttons that users can use to share content from this online offering with other Facebook users. To see a list of Facebook’s social plugins and what they look like, please click here: <a href="https://developers.facebook.com/docs/plugins/">https://developers.facebook.com/docs/plugins/</a>; Provider: <a href="https://www.facebook.com">https://www.facebook.com</a>, Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland, parent company: Facebook, 1 Hacker Way, Menlo Park, CA 94025, USA; website: <a href="https://www.facebook.com">https://www.facebook.com</a>; Privacy Policy: <a href="https://www.facebook.com/about/privacy">https://www.facebook.com/about/privacy</a>; Facebook adheres to the EU-US Privacy Shield, <a href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>. Opt-Out: Settings for ads: <a href="https://www.facebook.com/settings?tab=ads">https://www.facebook.com/settings?tab=ads</a>.
    </p>

    <p>
      <strong>Google Maps:</strong> We incorporate maps from Google Maps, which is a service of Google. The data processed may include in particular users' IP addresses and locations; however, this data may not be collected without the users' consent (usually through the settings on their mobile devices). Provider: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland, parent company: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; website: <a href="https://www.google.com/maps/">https://www.google.com/maps/</a>; Privacy Policy: <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>; Google adheres to the EU-US Privacy Shield, <a href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>. Opt-Out: Opt-Out Plugin: <a href="https://tools.google.com/dlpage/gaoptout?hl=en">https://tools.google.com/dlpage/gaoptout?hl=en</a>.
    </p>

    <p><strong>Instagram Plugins and Buttons:</strong> Instagram plugins and buttons - These may include, for example, content such as photos, videos or copy, and buttons that users can use to share content from this online offering with other Instagram users. Provider: <a href="https://www.instagram.com">https://www.instagram.com</a>, Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA; website: <a href="https://www.instagram.com">https://www.instagram.com</a>; Privacy Policy: <a href="http://instagram.com/about/legal/privacy">http://instagram.com/about/legal/privacy</a>.</p>

    <p><strong>Twitter:</strong> Social network; Provider: Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA; Privacy Policy: <a href="https://twitter.com/en/privacy">https://twitter.com/en/privacy</a>, (settings) <a href="https://twitter.com/personalization">https://twitter.com/personalization</a>; Twitter adheres to the EU-US Privacy Shield, <a href="https://www.privacyshield.gov/EU-US-Framework">https://www.privacyshield.gov/EU-US-Framework</a>.</p>

    <p><strong>Snapchat:</strong> Provider: Snap Inc., 63 Market Street, CA 90291 Venice, USA: Privacy Policy: <a href="https://www.snap.com/en-US/privacy/privacy-policy/">https://www.snap.com/en-US/privacy/privacy-policy/</a>.</p>

    <h2>X. Deployment of Analysis Tools</h2>
    <p>When you use our app, your behavior can be statistically evaluated with the help of certain analytic tools and then analyzed for advertising and marketing purposes or to improve our offerings. We ensure that we comply with the provisions of data protection law when deploying such tools. Where external service providers (data processors) are used, we ensure that processing is carried out in accordance with German and European data protection standards by concluding the appropriate contracts with such providers.</p>

    <h3>Usage Analysis with Google Analytics Firebase</h3>
    <p>We use Google Analytics Firebase (hereinafter referred to as “Google Firebase”) to analyze user behavior. The provider is Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. In addition, we use Firebase’s features to enhance the user experience or to allow us to evaluate the causes of the app crashing. For this purpose, anonymous data (e.g. number and length of sessions, operating systems, device models and region) are transferred to Firebase. You will find a detailed overview of Google Firebase’s data collection here: <a href="https://support.google.com/firebase/answer/6318039?hl=en">https://support.google.com/firebase/answer/6318039?hl=en</a> Firebase is an integral technical component of our offering and cannot be deactivated. Google Firebase is used to enhance this app and to improve our offerings. This constitutes a legitimate interest within the meaning of Art. 6(1)(f) of the GDPR. Further information is available at <a href="https://www.firebase.com/terms/privacy-policy.html">https://www.firebase.com/terms/privacy-policy.html</a>.</p>

    <h3>Usage Analysis with Fabric crashlytics</h3>
    <p>We use the development tool Fabric in our mobile applications to evaluate useability and user interactions within the app. Fabric is a tool provided by Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. You can access Google’s Privacy Policy using the following link: <a href="https://policies.google.com/?hl=en&gl=de">https://policies.google.com/?hl=en&gl=de</a> You can access Fabric’s Terms of Service via the following link: <a href="https://fabric.io/terms">https://fabric.io/terms</a> Our use of Fabric is based on our legitimate interests (analysis, further development, improvement, enhancement and security) pursuant to Art. 6(1)(f) of the GDPR since it serves the continual improvement of the mobile application and the related services. We use Crashlytics for the evaluation of the interactions. Crashlytics provides information on the usage of the app and useful data on unforeseen crashes or other abnormal app behavior.</p>

    <h2>XI. Werbung</h2>

    <h3>Datenschutzerklärung für die Nutzung von AdMob/Werbung</h3>
    <p>Für Werbeeinblendungen nutzen wir den Werbepartner AdMob Google Inc. Admob sammelt persönliche Daten wie Device IDs, GPS-Daten, demographische Daten u.a. um für Sie relevante Werbung anzeigen zu können. Der Einsatz von Google AdMob und die damit zusammenhängende Datenverarbeitung wird auf Grundlage unserer berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO durchgeführt. Über die Nutzung von Google AdMob wollen wir sicherstellen, dass Ihnen nur an Ihren tatsächlichen oder vermeintlichen Interessen orientierte Werbung auf Ihren Endgeräten eingeblendet wird. Weitere Details zu AdMob finden Sie unter <a href="http://www.admob.de">http://www.admob.de</a> und zu den von Google Admob erhobenen Daten in der Datenschutzerklärung von Google: <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a></p>

    <h2>XI. Advertising</h2>

    <h3>Privacy Policy for the Use of AdMob/Advertising</h3>

    <p>We use the advertising company AdMob Google Inc. for showing ads. Admob collects personal data such as device IDs, GPS data, demographic data and other similar information to be able to show you advertising that is relevant for you. The use of Google AdMob and the related data processing is based on our legitimate interests in accordance with Art. 6 (1)(f) of the GDPR. By using Google AdMob, we want to ensure that you are only shown ads on your end device that relate to your actual or presumed interests. You will find further information about AdMob at <a href="https://admob.google.com/home/">https://admob.google.com/home/</a> and as regards the data collected by Google Admob in Google’s Privacy Policy: <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.</p>

    <h2>XII. Your Rights</h2>

    <p>Where we process your personal data, you may exercise the following rights against us in relation to such data:</p>

    <h3>Right to Access, Art. 15 of the GDPR:</h3>
    <p>You have the right to request information from the controller on whether it is processing your personal data. If that should be the case, you can request information on the following from the controller:</p>

    <ul>
      <li>the purposes for which the personal data are processed;</li>
      <li>the categories of personal data processed;</li>
      <li>the recipients or categories of recipients to whom your personal data are or have been disclosed, in particular in the case of recipients in other countries or international organizations; in the above-mentioned cases, you can require that you be notified of appropriate safeguards pursuant to Art. 46 of the GDPR in connection with any such transfer;</li>
      <li>the contemplated duration of storage of your personal data or, if concrete information cannot be provided, the criteria for determining the duration of storage;</li>
      <li>the existence of a right to rectification or erasure of your personal data, a right to restriction of processing by the controller or a right to object to such processing;</li>
      <li>the right to lodge complaints with a supervisory authority;</li>
      <li>all available information on the origin of personal data that was not obtained from the data subject;</li>
      <li>the existence of automated decision-making, including profiling pursuant to Art. 22(1) and (4) of the GDPR and – at least in these cases – meaningful information on the logic involved and the scope and intended effects of such processing for the data subject.</li>
    </ul>

    <h3>Right to Rectification, Art. 16 of the GDPR:</h3>
    <p>You have the right to require that the controller rectify and/or complete your personal data if the data that are processed are inaccurate or incomplete. The controller must make such changes without undue delay.</p>

    <h3>Right to Erasure, Art. 17 of the GDPR:</h3>

    <h4>Duty to erase</h4>
    <p>You have the right to require that the controller erase personal data concerning you without undue delay. The controller must then erase such personal data without undue delay if one of the following grounds applies:</p>

    <ul>
      <li>the personal data are no longer needed for the purposes for which they were originally collected or otherwise processed;</li>
      <li>you have withdrawn the consent to processing given pursuant to of Art. 6(1)(a) or Art. 9(2)(a) of the GDPR and there is no other legal ground for such processing;</li>
      <li>you object to the processing pursuant to Art. 21(1) of the GDPR (cf. section IX) and there are no overriding legitimate grounds for such processing or you object to the processing pursuant to Art. 21(2) of the GDPR;</li>
      <li>your personal data were processed unlawfully;</li>
      <li>your personal data must be erased to comply with a legal obligation under Union or Member State law to which the controller is subject;</li>
      <li>your personal data were collected in connection with an offer of information society services pursuant to Art. 8(1) of the GDPR;</li>
    </ul>

    <h4>Information provided to third parties</h4>
    <p>If the controller has made public personal data concerning you and is obliged to erase such data pursuant to Art. 17(1) of the GDPR, the controller, taking account of available technology and the cost of implementation, must take reasonable steps, including technical measures, to inform controllers which are processing the personal data that you, as the data subject, have requested the erasure by such controllers of any links to, or copy or replication of, those personal data.</p>

    <h4>Exceptions</h4>
    <p>The right to erasure does not apply if processing is necessary</p>

    <ul>
      <li>for the exercise of the right to freedom of expression and information;</li>
      <li>to comply with a legal obligation that requires processing under Union or Member State law to which the controller is subject or for the performance of a task carried out in the public interest or to exercise official authority vested in the controller;</li>
      <li>for reasons of public interest in the area of the public health pursuant to Art. 9(2)(h) and (i) and Art. 9(3) of the GDPR;</li>
      <li>for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes in accordance with Art. 89(1) of the GDPR insofar as the right referred to in point a) is likely to render impossible or seriously impair the achievement of the objectives of such processing; or</li>
      <li>if necessary to establish, exercise or defend legal claims.</li>
    </ul>

    <h3>Right to Restrict Processing, Art. 18 of the GDPR:</h3>

    <p>You have the right to require that the controller restrict processing of your personal data under the following conditions:</p>

    <ul>
      <li>if you contest the accuracy of personal data for a period that allows the controller to verify the accuracy of the respective personal data;</li>
      <li>if the processing is unlawful and you oppose erasure of the personal data and request restriction of their use instead;</li>
      <li>if the controller no longer needs the personal data for the purposes of processing, but you need the personal data to establish, exercise or defend legal claims; or</li>
      <li>if you have objected to processing pursuant to Art. 21(1) of the GDPR (cf. section XIII) pending verification of whether the legitimate grounds of the controller override your grounds.</li>
    </ul>

    <p>If the processing of your personal data has been restricted, such personal data may, except as regards storage, be processed only with your consent or for the establishment, exercise or defense of legal claims or for the protection of the rights of another natural or legal person or for reasons of important public interest of the Union or of a Member State.</p>

    <p>If you have succeeded in restricting processing as described above, you will be notified by the controller before such restriction is lifted.</p>

    <h3>Right to Notification, Art. 19 of the GDPR:</h3>
    <p>If you have the right to require that a controller rectify, erase or restrict processing, the controller must notify all recipients to whom personal data concerning you were disclosed of such rectification, erasure or restriction of processing unless notification proves impossible or would entail an unreasonable effort.</p>

    <p>You have the right to be notified of such recipients by the controller.</p>

    <h3>Right to Data Portability, Art. 20 of the GDPR:</h3>
    <p>You have right to receive, in a structured, commonly used and machine-readable format, the personal data concerning you that you have made available to a controller. You also have the right to transmit such data to another controller without hindrance from the controller to which the personal data were provided</p>

    <ul>
      <li>if processing is based on consent pursuant to Art. 6(1)(a) of the GDPR or Art. 9(2)(a) GDPR or on a contract pursuant to Art. 6(1)(b) of the GDPR and</li>
      <li>processing is carried out by automated means.</li>
    </ul>

    <p>In exercising this right, you also have the right to have personal data concerning you transmitted directly from one controller to another if technically feasible. This may not be allowed to adversely affect the freedoms and rights of others.</p>

    <p>The right to erasure remains unaffected.</p>

    <p>The right to data portability does not apply to the processing of personal data required for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller.</p>

    <h3>Right to Object, Art. 21 of the GDPR:</h3>
    <p>You have the right to object, on grounds relating to your particular situation, to processing of your personal data and to the processing of your data for advertising purposes. For further information about this, please refer to section XIII of this Privacy Policy.</p>

    <h3>Right to Withdraw Consent under Data Protection Law:</h3>
    <p>You may withdraw your consent to our processing of your personal data at any time. Please remember that your withdrawal is only effective with regard to future processing. Withdrawal of consent will not affect the lawfulness of processing based on your consent prior to its withdrawal.</p>

    <h3>Automated Individual Decision-Making, Including Profiling, Art. 22 of the GDPR:</h3>
    <p>You have the right not to be subject to a decision based solely on automated processing, including profiling, where such decision legally affects you or entails effects that are of similar importance. This will not apply in the case of any decision that is</p>

    <ol>
      <li>necessary for the entry into or performance of a contract between you and the controller;</li>
      <li>permissible under Union or Member State law to which the controller is subject and which also lays down suitable measures to safeguard your rights and freedoms and legitimate interests; or</li>
      <li>based on your explicit consent.</li>
    </ol>

    <p>In the cases referred to in (1) and (3) above, the controller must implement suitable measures to safeguard your rights and freedoms as well as your legitimate interests; such measures must at least include the right to obtain human intervention on the part of the controller, to express your point of view and to contest the decision.</p>

    <p>However, a decision based solely on automated processing may not be based on special categories of personal data pursuant to Art. 9(1) of the GDPR unless Art. 9(2)(a) or (g) of the GDPR applies and suitable measures have been taken to safeguard your rights and freedoms as well as your legitimate interests.</p>

    <h3>Right to Lodge Complaints with a Supervisory Authority, Art. 77 of the GDPR:</h3>
    <p>Additionally, you have the right to lodge a complaint about the processing of your data with a data protection authority. You may address your complaints to the supervisory authority in the Member State of your residence, place of work or the place of the alleged infringement. The supervisory authority with which the complaint is lodged will inform you as the complainant on the progress and the outcome of the complaint including the possibility of a judicial remedy pursuant to Art. 78 of the GDPR.</p>

    <h2>XIII. Right to Object Pursuant to Art. 21 of the GDPR</h2>

    <h3>Right to Object in a Particular Case:</h3>
    <p>You have the right to object, on grounds related to your particular situation, at any time to the processing of personal data concerning you on the basis of Art. 6(1)(e) of the GDPR (processing carried out in the public interest) and Art. 6(1)(f) of the GDPR (processing for the purposes of the legitimate interests of the controller or a third party); this applies accordingly as regards profiling based on these provisions. Where you exercise your right to object, we will then cease to process your personal data unless we can demonstrate compelling legitimate reasons for such processing that outweigh your interests, rights and freedoms, or where such processing serves to establish, exercise or defend legal claims.</p>

    <h3>Right to Object to the Processing of Data for Advertising Purposes:</h3>

    <p>In certain cases, we process your personal data for direct advertising. You may, at any time, object to the processing of your personal data for such advertising purposes; this will apply accordingly to any profiling related to such direct marketing activities. Where you object to processing for direct marketing purposes, we will no longer process your personal data for such purposes.</p>

    <p>There are no formal requirements for lodging an objection in the above-mentioned cases. To do so, simply e-mail us at the address below and insert “Objection” in the subject line:</p>

    <p>Snoopo GmbH, Data Protection Officer, Berliner Allee 34-36, 40212 Düsseldorf; E-mail: <a href="mailto:datenschutz@snoopo.com">datenschutz@snoopo.com</a> </p>
        </Container>
    </Layout>
  )
}

export default PrivacyPolicy
